<template>
  <div></div>
</template>
<script>
import api from "@/api";
export default {
  name: "TempToken",
  mounted() {
    // console.log();
    // this.$store.dispatch("utility/ChangeLoading", true);
    api.defaults.headers["X-App-Name"] = this.$route.query.domen;
    this.$store.dispatch("user/TEMPLOGIN", {
      vm: this,
      code: this.$route.params.id,
      domain: this.$route.query.domen,
    });

    // this.$store.dispatch("utility/ChangeLoading", false);
  },
};
</script>
